import { addEventDelegate } from "@jeffcarbine/premmio/public/modules/eventDelegate/eventDelegate.js";
import { parallax } from "@jeffcarbine/premmio/public/modules/parallax/parallax.js";

parallax(
  ".sectionTransition, section:nth-of-type(1):not(#splash) article, .feature"
);

const setScrolledValue = (scrollPos) => {
  const header = document.querySelector("header");

  if (scrollPos > 250) {
    header.classList.add("scrolled");
  } else {
    header.classList.remove("scrolled");
  }
};

addEventDelegate("scroll", window, setScrolledValue);
